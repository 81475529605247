const API_URL = process.env.REACT_APP_API_URL;

export async function getAllProjects() {
  const url = `${API_URL}/project`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

export async function cloneProject(projectUrl) {
  const url = `${API_URL}/project`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ url: projectUrl }),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}

export async function removeProject(projectName) {
  const url = `${API_URL}/project/${projectName}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}
