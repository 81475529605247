import React from 'react';
import { useTranslation } from 'react-i18next';
import { DATA_TYPE, DATA_TYPES } from './TemplateEditor';
import { IconButton } from '../util';

export default function TemplateEditorProperty(props) {
  const { t } = useTranslation();
  const {
    property = {},
    onChange,
    removeMode = false,
    onRemove,
    onMoveUp,
    onMoveDown,
    className = '',
  } = props;
  const { name, dataType, inputType } = property;

  function handleChange(key, value) {
    if (onChange) {
      if (key === 'dataType') {
        onChange({
          ...property,
          [key]: value,
          inputType: DATA_TYPE[value][0],
        });
      } else {
        onChange({ ...property, [key]: value });
      }
    }
  }

  return (
    <div className={`form-row animated faster fadeInDown ${className}`}>
      <div className="col-3">
        <div className="form-group">
          <label className="small mb-0 font-weight-light text-secondary">
            {t('Name')}
          </label>
          <input
            type="text"
            value={name}
            className="form-control"
            onChange={(e) => handleChange('name', e.target.value)}
            placeholder={t('Enter name')}
          />
        </div>
      </div>

      <div className="col-3">
        <div className="form-group">
          <label className="small mb-0 font-weight-light text-secondary">
            {t('Data type')}
          </label>
          <select
            value={dataType}
            onChange={(e) => handleChange('dataType', e.target.value)}
            className="form-control"
          >
            {DATA_TYPES.map((dataType) => (
              <option key={dataType}>{dataType}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-3">
        <div className="form-group">
          <label className="small mb-0 font-weight-light text-secondary">
            {t('Input type')}
          </label>
          {dataType && (
            <select
              value={inputType}
              onChange={(e) => handleChange('inputType', e.target.value)}
              className="form-control"
            >
              {DATA_TYPE[dataType].map((inputType) => (
                <option key={inputType}>{inputType}</option>
              ))}
            </select>
          )}
        </div>
      </div>

      <div className="col-3 text-right">
        {removeMode ? (
          <IconButton
            light
            icon="trash"
            className="text-danger h3 mb-0 animated faster lightSpeedIn"
            onClick={onRemove}
          />
        ) : (
          <div className="animated faster fadeInLeft">
            <IconButton
              light
              icon="arrow-up"
              className="h5 mr-2 mb-0 animated faster lightSpeedIn"
              onClick={onMoveUp}
              tooltip={t('move up')}
            />
            <IconButton
              light
              icon="arrow-down"
              className="h5 mb-0 animated faster lightSpeedIn"
              onClick={onMoveDown}
              tooltip={t('move down')}
            />
          </div>
        )}
      </div>
    </div>
  );
}
