import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ContentEditor from './ContentEditor';
import { getTemplate } from '../api/template.api';
import { getContent } from '../api/content.api';
import { Spinner, Alert } from '../util';
import BreadCrumb from '../component/BreadCrumb';

export default function Content(props) {
  const { t } = useTranslation();
  const { projectName, templateName, contentId } = useParams();

  const [template, setTemplate] = React.useState();
  const [content, setContent] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  React.useEffect(() => {
    if (!projectName || !templateName || !contentId) {
      return;
    }

    async function fetchTemplateAndContent() {
      try {
        setLoading(true);
        setAlert({});

        const template = await getTemplate(projectName, templateName);
        setTemplate(template);

        const content = await getContent(projectName, templateName, contentId);
        setContent(content);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAlert({ type: 'warning', message: `${error}` });
      }
    }
    fetchTemplateAndContent();
  }, [projectName, templateName, contentId]);

  return (
    <div className="container-lg py-5">
      <BreadCrumb
        projectName={projectName}
        templateName={templateName}
        content={content}
        isContents
      />

      <div className="p-3 rounded-lg border shadow-sm mb-4">
        <small data-tootik={t('Project')}>{projectName}</small>
        <span className="mx-2 text-secondary">|</span>
        <small data-tootik={t('Template')}>{templateName}</small>
        <span className="mx-2 text-secondary">|</span>
        <small data-tootik={t('ID')}>{contentId}</small>
      </div>

      <Spinner show={loading} />
      <Alert {...alert} />

      <ContentEditor
        projectName={projectName}
        template={template}
        content={content}
        setContent={setContent}
      />
    </div>
  );
}
