import React from 'react';
import { useTranslation } from 'react-i18next';
import { uploadImage } from '../api/image.api';
import { IconButton, Spinner, Alert } from '../util';

const PREVIEW_STYLE = { width: 120, height: 120, objectFit: 'contain' };

export default function ProjectImageListUpload(props) {
  const { t } = useTranslation();
  const { projectName, onUpload } = props;

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  const [file, setFile] = React.useState();
  const inputFile = React.useRef();

  function handleClick(e) {
    try {
      inputFile.current.click();
    } catch (error) {
      setAlert({ type: 'warning', message: `${error}` });
    }
  }

  function handleChange(e) {
    try {
      setFile(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      setAlert({ type: 'warning', message: `${error}` });
    }
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      setAlert({});
      setLoading(true);

      await uploadImage(projectName, inputFile.current.files[0]);

      if (onUpload) {
        onUpload();
      }

      setFile();
      setLoading(false);
    } catch (error) {
      setAlert({ type: 'warning', message: `${error}` });
      setLoading(false);
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group text-center">
          <button
            className="btn btn-info shadow-sm rounded-pill px-4 animated faster lightSpeedIn delay-500ms"
            onClick={handleClick}
            type="button"
          >
            {file ? t('Select different image') : t('Upload an image')}
          </button>
          <input
            id="inputFile"
            ref={inputFile}
            type="file"
            accept="image/*"
            onChange={handleChange}
            className="form-control p-1"
            style={{ display: 'none' }}
          />
        </div>

        <Spinner show={loading} />
        <Alert {...alert} />

        {file && (
          <div className="text-center p-3">
            <div className="text-right">
              <IconButton light icon="close" onClick={(e) => setFile()} />
            </div>
            {/*<label className="font-weight-light animated faster fadeInUp delay-750ms">
              {t('Preview')}
            </label>*/}
            <div className="text-center">
              <img
                src={file}
                className="p-1 border rounded shadow-sm mb-3 animated faster zoomIn"
                alt=""
                style={PREVIEW_STYLE}
              />
            </div>
            <button
              className="btn btn-success rounded-pill px-4 shadow-sm animated faster fadeInDown delay-500ms"
              type="submit"
            >
              {t('Upload')}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
