import React from 'react';
import Navbar from './Navbar';
import Main from './Main';
import Footer from './Footer';

export default function Body(props) {
  return (
    <React.Fragment>
      <Navbar />
      <Main />
      <Footer />
    </React.Fragment>
  );
}
