import React from 'react';
import { generateId } from '../util';
import ContentEditorFormPropertyInput from './ContentEditorFormPropertyInput';

export default function ContentEditorFormProperty(props) {
  const { projectName, property, value, onChange, setImageModal } = props;

  const { name, dataType, inputType } = property;

  const [id] = React.useState(generateId());

  return (
    <div className="form-group">
      <label htmlFor={id}>{name}</label>
      <ContentEditorFormPropertyInput
        id={id}
        projectName={projectName}
        name={name}
        inputType={inputType}
        dataType={dataType}
        value={value}
        onChange={onChange}
        setImageModal={setImageModal}
      />
    </div>
  );
}
