import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ProjectTemplateListEntry(props) {
  const { t } = useTranslation();
  const { template, className = '' } = props;
  const { name, description, properties = [] } = template;
  return (
    <div className={`p-3 rounded-lg shadow-sm mb-3 shadow-hover ${className}`}>
      <h5>{name}</h5>
      <p className="font-weight-light">{description}</p>
      <div>
        <h6 className="text-secondary">{t('Properties')}</h6>
        {properties.map(({ name, dataType, inputType }, i) => (
          <span
            key={name}
            className="badge badge-pill badge-primary px-3 mr-2 mb-2"
          >
            {name}
          </span>
        ))}
      </div>
    </div>
  );
}
