import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumb from '../component/BreadCrumb';
import HomeProjectList from './HomeProjectList';

export default function Home(props) {
  const { t } = useTranslation();
  return (
    <div className="container-lg py-5">
      <BreadCrumb />

      <h3>{t('Home')}</h3>

      <HomeProjectList />
    </div>
  );
}
