import React from 'react';
import { useTranslation } from 'react-i18next';
import { getImages, removeImage } from '../api/image.api';
import { Modal, InputSwitch, Spinner, Alert } from '../util';
import ProjectImageListEntry from './ProjectImageListEntry';
import ProjectImageListUpload from './ProjectImageListUpload';

function sortByCreated(images = []) {
  return images.sort((a, b) => {
    const x = a.created;
    const y = b.created;
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
}

export default function ProjectImageList(props) {
  const { t } = useTranslation();
  const { projectName, selected, onChange, className = '' } = props;

  const [images, setImages] = React.useState([]);
  const [selectedImage, setSelectedImage] = React.useState();
  const [removing, setRemoving] = React.useState();

  const [isUploadModal, setIsUploadModal] = React.useState(false);
  const [isRemove, setIsRemove] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  React.useEffect(() => {
    if (!selected || selectedImage) {
      return;
    }
    const { name } = selected;
    const image = images.find((image) => image.name === name);
    setSelectedImage(image);
  }, [images, selected, selectedImage]);

  React.useEffect(() => {
    if (!projectName) {
      return;
    }
    async function fetchImages() {
      try {
        setLoading(true);
        setAlert({});

        const images = await getImages(projectName);
        setImages(sortByCreated(images));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAlert({ type: 'warning', message: `${error}` });
      }
    }
    fetchImages();
  }, [projectName]);

  async function handleOnUpload() {
    try {
      setLoading(true);
      setAlert({});

      const images = await getImages(projectName);
      setImages(sortByCreated(images));
      setSelectedImage(images[images.length - 1]);
      setIsUploadModal(false);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlert({ type: 'warning', message: `${error}` });
    }
  }

  async function handleRemove(image) {
    if (!image) {
      return;
    }
    try {
      setLoading(true);
      setAlert({});

      await removeImage(projectName, image.name);

      const images = await getImages(projectName);
      setImages(sortByCreated(images));
      setRemoving();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlert({ type: 'warning', message: `${error}` });
    }
  }

  return (
    <div className={className}>
      <h4>
        {t('Images')}
        <span className="ml-2 badge badge-info badge-pill">
          {images.length}
        </span>
      </h4>

      <Spinner show={loading} />
      <Alert {...alert} />

      <Modal
        show={isUploadModal}
        title={t('Upload image')}
        onClose={(e) => setIsUploadModal(false)}
      >
        <ProjectImageListUpload
          projectName={projectName}
          onUpload={handleOnUpload}
        />
      </Modal>

      <Modal
        show={removing}
        title={t('Remove image')}
        onClose={(e) => setRemoving()}
        onOK={(e) => handleRemove(removing)}
        okLabel={t('Remove')}
      >
        {removing && (
          <div className="text-center">
            <h5 className="text-danger">
              {t('Are you sure to remove this image?')}
            </h5>
            <ProjectImageListEntry
              projectName={projectName}
              image={removing}
              className="d-inline-block"
            />
          </div>
        )}
      </Modal>

      <div className="mb-4 text-right">
        <InputSwitch
          className="d-inline-block mr-4"
          label={t('Remove image')}
          value={isRemove}
          onChange={(e) => setIsRemove(!isRemove)}
        />
        <button
          type="button"
          className="btn btn-success rounded-pill px-4"
          onClick={(e) => setIsUploadModal(true)}
        >
          {t('Upload new image')}
        </button>
      </div>

      <div className="form-row">
        {images.map((image) => (
          <div
            key={image.name}
            className="position-relative col-4 col-sm-3 col-md-2 col-xl-1 mb-3"
          >
            <button
              className="btn-unstyled shadow-hover rounded-lg"
              onClick={(e) =>
                isRemove ? setRemoving(image) : setSelectedImage(image)
              }
            >
              {isRemove && (
                <div
                  className="position-absolute h-100 w-100 text-center text-danger animated faster zoomIn"
                  style={{ zIndex: 99, background: 'rgba(255,255,255,.5)' }}
                >
                  <h5 className="py-4">{t('Remove')}</h5>
                </div>
              )}

              <ProjectImageListEntry
                projectName={projectName}
                image={image}
                isHighlight={selectedImage === image}
              />
            </button>
          </div>
        ))}
      </div>

      {onChange && (
        <div className="p-3 text-center animated faster fadeInUp">
          <button
            className="btn btn-primary rounded-pill px-4"
            onClick={(e) => onChange(selectedImage)}
          >
            {t('Change')}
          </button>
        </div>
      )}
    </div>
  );
}
