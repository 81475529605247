import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import 'tootik/css/tootik.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
//import './style/bootstrap.materia.min.css';
import './style/bootstrap.materia.nofont.min.css';
import './style/style.css';
//import './style/font.css';
import './style/thinker.css';
import './style/media.css';
import './style/override.css';
import Body from './component/Body';

export default function App() {
  return <Body />;
}
