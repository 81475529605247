import React from 'react';

export function Details(props) {
  const { summary = '', className = '', style = {}, children } = props;
  return (
    <details className={className} style={style}>
      <summary style={{ outline: 'none' }}>{summary}</summary>
      {children}
    </details>
  );
}
