import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from '../util';
import { getAllProjects /*removeProject*/ } from '../api/project.api.js';

export default function Home(props) {
  const { t } = useTranslation();

  const [projectNames, setProjectNames] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  React.useEffect(() => {
    async function fetchProjects() {
      try {
        setLoading(true);
        const response = await getAllProjects();
        setProjectNames(response);
        setLoading(false);
      } catch (error) {
        setAlert({ type: 'warning', message: `${error}` });
        setLoading(false);
      }
    }
    fetchProjects();
  }, []);

  return (
    <div>
      <h3 className="mb-4">{t('Project List')}</h3>

      <Spinner show={loading} />
      <Alert {...alert} />

      <div className="list-group">
        {projectNames.map((projectName) => (
          <Link
            key={projectName}
            to={`/p/${projectName}`}
            className="list-group-item list-group-item-action"
          >
            {projectName}
          </Link>
        ))}
      </div>
    </div>
  );
}
