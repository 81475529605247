import React from 'react';

const ICON_BUTTON_STYLE = { width: 48, height: 48 };

export function IconButton(props) {
  const {
    type = 'button',
    icon = '',
    className = '',
    style = {},
    primary = true,
    secondary = false,
    info = false,
    success = false,
    warning = false,
    danger = false,
    light = false,
    dark = false,
    border = false,
    shadow = 'sm',
    iconColor = '',
    onClick,
    children,
    disabled = false,
  } = props;
  const classNames = ['btn btn-lg rounded-circle', className];
  if (primary) {
    classNames.push('btn-primary');
  }
  if (secondary) {
    classNames.push('btn-secondary');
  }
  if (info) {
    classNames.push('btn-info');
  }
  if (success) {
    classNames.push('btn-success');
  }
  if (warning) {
    classNames.push('btn-warning');
  }
  if (danger) {
    classNames.push('btn-danger');
  }
  if (light) {
    classNames.push('btn-light');
  }
  if (dark) {
    classNames.push('btn-dark');
  }
  if (border) {
    classNames.push('border');
  }
  if (shadow) {
    switch (shadow) {
      case 'sm':
      case 'lg':
        classNames.push(`shadow-${shadow}`);
        break;
      default:
        classNames.push('shadow');
    }
  }
  return (
    <button
      type={type}
      className={classNames.join(' ')}
      style={{ ...ICON_BUTTON_STYLE, ...style }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <i
          className={`icon-${icon} icons ${
            iconColor ? `text-${iconColor}` : ''
          }`}
        ></i>
      )}
      <span className={`text-${iconColor}`}>{children}</span>
    </button>
  );
}
