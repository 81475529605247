import React from 'react';
import { useTranslation } from 'react-i18next';
import TemplateEditorProperty from './TemplateEditorProperty';
import {
  IconButton,
  InputSwitch,
  Details,
  PreCode,
  Spinner,
  Alert,
} from '../util';
import { updateTemplate } from '../api/template.api';

export const DATA_TYPE = {
  string: ['text', 'url', 'textarea', 'md', 'html'],
  number: ['number'],
  date: ['date'],
  file: ['image', 'video', 'other'],
  json: ['object', 'array'],
  // for linked to other type of content
  //content: [//templateNames]
  //contents: [//templateNames]
  id: ['n/a'],
  now: ['n/a'],
};

export const DATA_TYPES = Object.keys(DATA_TYPE);

export default function TemplateEditor(props) {
  const { t } = useTranslation();
  const { projectName, templateName, template = {}, setTemplate } = props;

  const { description = '', properties = [] } = template;
  const [removeMode, setRemoveMode] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  React.useEffect(() => {
    if (properties.length <= 0) {
      setTemplate({
        ...template,
        properties: [
          {
            name: '',
            dataType: DATA_TYPES[0],
            inputType: DATA_TYPE[DATA_TYPES[0]][0],
          },
        ],
      });
    }
  }, [properties, template, setTemplate]);

  function addProperty() {
    const { properties = [] } = template;
    setTemplate({
      ...template,
      properties: [
        ...properties,
        {
          name: '',
          dataType: DATA_TYPES[0],
          inputType: DATA_TYPE[DATA_TYPES[0]][0],
        },
      ],
    });
  }

  function handlePropertyChange(property, i) {
    const { properties = [] } = template;
    setTemplate({
      ...template,
      properties: [
        ...properties.slice(0, i),
        property,
        ...properties.slice(i + 1),
      ],
    });
  }

  function removeProperty(i) {
    const { properties = [] } = template;
    setTemplate({
      ...template,
      properties: [...properties.slice(0, i), ...properties.slice(i + 1)],
    });
  }

  function moveUp(i) {
    if (i <= 0) {
      return;
    }
    const { properties = [] } = template;
    setTemplate({
      ...template,
      properties: [
        ...properties.slice(0, i - 1),
        properties[i],
        properties[i - 1],
        ...properties.slice(i + 1),
      ],
    });
  }

  function moveDown(i) {
    const { properties = [] } = template;
    if (i + 1 >= properties.length) {
      return;
    }
    setTemplate({
      ...template,
      properties: [
        ...properties.slice(0, i),
        properties[i + 1],
        properties[i],
        ...properties.slice(i + 2),
      ],
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setAlert({});
      setLoading(true);

      await updateTemplate(projectName, templateName, template);

      setAlert({ type: 'success', message: t('Saved') });
      setLoading(false);
    } catch (error) {
      setAlert({ type: 'warning', message: `${error}` });
      setLoading(false);
    }
  }

  return (
    <div>
      <h3>
        {templateName}
        <small className="ml-2">{t('Template')}</small>
      </h3>

      <Spinner show={loading} />
      <Alert {...alert} />

      <Details summary={t('View template')}>
        <PreCode>{template}</PreCode>
      </Details>

      <form onSubmit={handleSubmit}>
        <div className="text-right mb-3">
          <button
            className="btn btn-primary rounded-pill px-4 shadow-sm"
            type="submit"
          >
            {t('Save')}
          </button>
        </div>
        <div className="form-group">
          <label
            htmlFor="description"
            className="small mb-0 font-weight-light text-secondary"
          >
            {t('Template description')}
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) =>
              setTemplate({ ...template, description: e.target.value })
            }
            className="form-control"
            placeholder={`Enter description`}
          />
        </div>

        <div className="mb-3 text-right">
          <InputSwitch
            value={removeMode}
            onChange={setRemoveMode}
            label={t('Remove')}
            className="d-inline-block mr-3"
          />
          <IconButton
            light
            icon="plus"
            className="h3 mb-0"
            onClick={addProperty}
          />
        </div>

        <div>
          {properties.map((property, i) => (
            <TemplateEditorProperty
              key={i}
              property={property}
              className="py-3 border-top"
              onChange={(property) => handlePropertyChange(property, i)}
              removeMode={removeMode}
              onRemove={() => removeProperty(i)}
              onMoveUp={() => moveUp(i)}
              onMoveDown={() => moveDown(i)}
            />
          ))}
        </div>

        <div className="py-3 text-right border-top">
          <InputSwitch
            value={removeMode}
            onChange={setRemoveMode}
            label={t('Remove')}
            className="d-inline-block mr-3"
          />
          <IconButton
            light
            icon="plus"
            className="h3 mb-0"
            onClick={addProperty}
          />
        </div>

        <div className="text-center mb-3">
          <button
            className="btn btn-primary rounded-pill px-4 shadow-sm"
            type="submit"
          >
            {t('Save')}
          </button>
        </div>
      </form>
    </div>
  );
}
