import React from 'react';

const API_URL = process.env.REACT_APP_API_URL;

export default function ProjectImageListEntry(props) {
  const { projectName, image = {}, isHighlight, className = '' } = props;
  const {
    name: imageName,
    //path,
    //width,
    //height,
    //aspectRatio,
    //mimetype,
    availableInWidths = [],
    //size,
    //created,
    //modified,
  } = image;

  let src = `${API_URL}/image/${projectName}/${imageName}`;
  if (availableInWidths.length > 0) {
    const width = availableInWidths[0];
    src = `${src}/${width}`;
  }

  return (
    <div className={`${className}`}>
      <img
        src={src}
        alt={src}
        className={`p-3 w-100 rounded shadow-sm animated faster zoomIn ${
          isHighlight ? 'border border-primary' : ''
        }`}
        style={{
          padding: 2,
          objectFit: 'contain',
          height: 100,
        }}
      />
    </div>
  );
}
