import React from 'react';
import { useTranslation } from 'react-i18next';
import ProjectImageListEntry from '../project/ProjectImageListEntry';

export default function ContentEditorFormPropertyInputImage(props) {
  const { t } = useTranslation();
  const { projectName, id, name, value, setImageModal } = props;

  function openImageModal() {
    setImageModal({ show: true, title: name, selected: value });
  }

  return (
    <div className="">
      {value && (
        <ProjectImageListEntry
          projectName={projectName}
          image={value}
          className="d-inline-block"
        />
      )}
      <div className="d-inline-block p-3">
        <button
          id={id}
          className="btn btn-success rounded-pill px-4"
          onClick={openImageModal}
          type="button"
        >
          {t('Change / Upload')}
        </button>
      </div>
    </div>
  );
}
