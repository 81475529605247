import React from 'react';

export function Spinner(props) {
  const {
    type,
    show = false,
    className = '',
    style = {},
    grow = false,
  } = props;
  if (!show) {
    return null;
  }
  const classNames = [className, 'px-2 py-4 text-center position-relative'];
  const spinnerClassName = [grow ? 'spinner-grow' : 'spinner-border'];
  switch (type) {
    case 'primary':
    case 'secondary':
    case 'info':
    case 'success':
    case 'warning':
    case 'danger':
    case 'light':
    case 'dark':
      spinnerClassName.push(`text-${type}`);
      break;
    default:
      break;
  }
  return (
    <div className={classNames.join(' ')} style={{ ...style, zIndex: 999 }}>
      <div className={spinnerClassName.join(' ')} role="status">
        <span className="sr-only">loading...</span>
      </div>
    </div>
  );
}
