import React from 'react';

export function Alert(props) {
  const { type, message, className = '', style = {}, onClose } = props;

  if (!message) {
    return null;
  }

  const classNames = [className, 'alert shadow-sm'];

  switch (type) {
    case 'info':
    case 'success':
      classNames.push(`alert-${type}`);
      classNames.push('animated faster fadeInDown');
      break;
    case 'warning':
    case 'danger':
      classNames.push(`alert-${type}`);
      classNames.push('animated faster bounceIn');
      break;
    default:
      classNames.push('alert-info');
  }

  return (
    <div className={classNames.join(' ')} style={style}>
      {message}
      {onClose && (
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
}
