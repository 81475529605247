import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTemplates } from '../api/template.api';
import ProjectTemplateListEntry from './ProjectTemplateListEntry';
import { Spinner, Alert } from '../util';

export default function ProjectTemplateList(props) {
  const { t } = useTranslation();
  const { projectName, templates, setTemplates, className = '' } = props;

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  React.useEffect(() => {
    if (!projectName) {
      return;
    }
    async function fetchTemplates() {
      try {
        setAlert({});
        setLoading(true);

        const templates = await getTemplates(projectName);
        setTemplates(templates);

        setLoading(false);
      } catch (error) {
        setAlert({ warning: 'danger', message: `${error}` });
        setLoading(false);
      }
    }
    fetchTemplates();
  }, [projectName, setTemplates]);

  return (
    <div className={className}>
      <h4>
        {t('Templates')}
        <span className="ml-2 badge badge-info badge-pill">
          {templates.length}
        </span>
      </h4>

      <Spinner show={loading} />
      <Alert {...alert} />

      <div className="form-row">
        {templates.map((template) => (
          <div key={template.name} className="col-12 col-sm-4">
            <Link to={`/p/${projectName}/t/${template.name}`}>
              <ProjectTemplateListEntry template={template} className="h-100" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
