import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../component/BreadCrumb';
import { Spinner, Alert } from '../util';
import { getTemplate } from '../api/template.api';
import TemplateEditor from './TemplateEditor';

export default function Template(props) {
  const { t } = useTranslation();
  const { projectName, templateName } = useParams();

  const [template, setTemplate] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  React.useEffect(() => {
    if (!projectName || !templateName) {
      return;
    }
    async function fetchTemplate() {
      try {
        setAlert({});
        setLoading(true);

        const template = await getTemplate(projectName, templateName);
        setTemplate(template);

        setLoading(false);
      } catch (error) {
        setAlert({ type: 'warning', message: `${error}` });
        setLoading(false);
      }
    }
    fetchTemplate();
  }, [projectName, templateName]);

  return (
    <div className="container-lg py-5">
      <BreadCrumb projectName={projectName} templateName={templateName} />
      <h5>{t('Template')}</h5>

      <Spinner show={loading} />
      <Alert {...alert} />

      <TemplateEditor
        projectName={projectName}
        templateName={templateName}
        template={template}
        setTemplate={setTemplate}
      />
    </div>
  );
}
