import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { getContents } from '../api/content.api';
import ContentListEntry from './ContentListEntry';
import BreadCrumb from '../component/BreadCrumb';
import { Spinner, Alert } from '../util';

function filterValidContents(contents = []) {
  return contents.filter((content = {}) => {
    const { value = {} } = content;
    return Object.keys(value).length > 0;
  });
}

export default function ContentList(props) {
  const { t } = useTranslation();
  const { projectName, templateName } = useParams();

  const [contents, setContents] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  React.useEffect(() => {
    if (!projectName || !templateName) {
      return;
    }
    async function fetchContents() {
      try {
        setAlert({});
        setLoading(true);

        const contents = await getContents(projectName, templateName);
        setContents(filterValidContents(contents));

        setLoading(false);
      } catch (error) {
        setAlert({ warning: 'danger', message: `${error}` });
        setLoading(false);
      }
    }
    fetchContents();
  }, [projectName, templateName]);

  async function reload() {
    try {
      setAlert({});
      setLoading(true);

      const contents = await getContents(projectName, templateName);
      setContents(filterValidContents(contents));

      setLoading(false);
    } catch (error) {
      setAlert({ warning: 'danger', message: `${error}` });
      setLoading(false);
    }
  }

  return (
    <div className="container-lg py-5">
      <BreadCrumb
        projectName={projectName}
        templateName={templateName}
        isContents
      />

      <div className="mb-4">
        <small>{projectName}</small>
        <h1>
          <small className="mr-2">{t('Contents of')}</small>
          <span className="mr-2">{templateName}</span>
          <span className="badge badge-info badge-pill">{contents.length}</span>
        </h1>
      </div>

      <div className="mb-4 text-right">
        <Link to={`/p/${projectName}/t/${templateName}/n`}>
          <button className="btn btn-success rounded-pill shadow-sm px-4">
            <span className="mr-1">{t('Create New')}</span>
            {templateName}
          </button>
        </Link>
      </div>

      <Spinner show={loading} />
      <Alert {...alert} />

      <div className="form-row">
        {contents.map((content) => (
          <div key={content.id} className="col-12 col-sm-3 mb-3">
            <ContentListEntry
              projectName={projectName}
              templateName={templateName}
              content={content}
              onRemove={reload}
              className="h-100"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
