import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Details, PreCode } from '../util';
import { removeContent } from '../api/content.api';

export default function ContentListEntry(props) {
  const { t } = useTranslation();
  const {
    projectName,
    templateName,
    content,
    className = '',
    onRemove,
  } = props;

  const [isRemove, setIsRemove] = React.useState(false);

  const { id, slug, value } = content;
  const { name, title } = value;

  function handleRemove() {
    removeContent(projectName, templateName, id);

    if (onRemove) {
      onRemove();
    }
  }

  return (
    <div
      className={`position-relative p-3 shadow-sm shadow-hover rounded-lg ${className}`}
    >
      <div className="position-absolute w-100">
        <Link to={`/p/${projectName}/t/${templateName}/c/${id}`}>
          <button
            className="position-absolute btn btn-light rounded-circle"
            style={{ width: 48, height: 48, right: 25 }}
          >
            {t('Edit')}
          </button>
        </Link>
      </div>

      {isRemove ? (
        <div className="position-absolute" style={{ right: 25, bottom: 5 }}>
          <button
            type="button"
            className="btn btn-light rounded-circle text-danger mr-3"
            style={{ width: 48, height: 48 }}
            onClick={handleRemove}
          >
            {t('Yes')}
          </button>
          <button
            type="button"
            className="btn btn-light rounded-circle text-primary"
            style={{ width: 48, height: 48 }}
            onClick={(e) => setIsRemove(false)}
          >
            {t('No')}
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="position-absolute btn btn-light rounded-circle text-danger"
          style={{ width: 48, height: 48, right: 25, bottom: 5 }}
          onClick={(e) => setIsRemove(true)}
        >
          {t('Remove')}
        </button>
      )}

      <small>{id}</small>
      <h3>{name || title || slug}</h3>
      <Details summary={t('View value')}>
        <PreCode className="p-3 bg-dark text-success small rounded">
          {value}
        </PreCode>
      </Details>
    </div>
  );
}
