const API_URL = process.env.REACT_APP_API_URL;

export async function getContentStats(projectName) {
  const url = `${API_URL}/content/${projectName}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

export async function getContents(projectName, templateName) {
  const url = `${API_URL}/content?projectName=${projectName}&templateName=${templateName}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

export async function getContent(projectName, templateName, contentId) {
  const url = `${API_URL}/content/${projectName}/${templateName}/${contentId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

// contentValue is key-value object
export async function createContent(
  projectName,
  templateName,
  contentValue = {},
) {
  const url = `${API_URL}/content`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      projectName,
      templateName,
      value: contentValue,
    }),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}

export async function updateContent(
  projectName,
  templateName,
  contentId,
  contentValue,
) {
  const url = `${API_URL}/content`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      projectName,
      templateName,
      contentId,
      value: contentValue,
    }),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}

export async function removeContent(projectName, templateName, contentId) {
  const url = `${API_URL}/content/${projectName}/${templateName}/${contentId}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}
