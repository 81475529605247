const API_URL = process.env.REACT_APP_API_URL;

export async function getImages(projectName) {
  const url = `${API_URL}/image?projectName=${projectName}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

export async function uploadImage(projectName, file) {
  const url = `${API_URL}/image`;

  const body = new FormData();
  body.append('projectName', projectName);
  body.append('file', file);

  const response = await fetch(url, { method: 'POST', body });

  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}

export async function removeImage(projectName, imageName) {
  const url = `${API_URL}/image/${projectName}/${imageName}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}
