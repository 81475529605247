import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Alert, Details, Modal, PreCode } from '../util';
import ContentEditorFormProperty from './ContentEditorFormProperty';
import ProjectImageList from '../project/ProjectImageList';
import { updateContent } from '../api/content.api';

export default function ContentEditor(props) {
  const { t } = useTranslation();
  const { projectName, template, content, setContent } = props;

  const [imageModal, setImageModal] = React.useState({});

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  if (!template || !content) {
    return null;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setAlert({});
      setLoading(true);

      await updateContent(
        projectName,
        template.name,
        content.id,
        content.value,
      );

      setAlert({ type: 'success', message: `Saved` });
      setLoading(false);
    } catch (error) {
      setAlert({ type: 'warning', message: `${error}` });
      setLoading(false);
    }
  }

  function handleChange(k, v) {
    setContent({ ...content, value: { ...content.value, [k]: v } });
  }

  function handleChangeImage(image) {
    const { title: propertyName } = imageModal;
    const { name, width, height, aspectRatio, availableInWidths, size } = image;
    handleChange(propertyName, {
      type: 'image',
      name,
      width,
      height,
      aspectRatio,
      availableInWidths,
      size,
    });
    setImageModal({});
  }

  const { properties } = template;
  const { value } = content;

  return (
    <div>
      <div className="mb-4">
        <small>
          {projectName}
          <span className="mx-2 text-secondary">|</span>
          {template.name}}
        </small>
        <h5>{t('Content Editor')}</h5>
      </div>

      <div className="mb-4">
        <Details summary={t('Template')}>
          <PreCode>{template}</PreCode>
        </Details>
        <Details summary={t('Content')}>
          <PreCode>{content}</PreCode>
        </Details>
      </div>

      <Modal {...imageModal} onClose={(e) => setImageModal({})}>
        <ProjectImageList
          projectName={projectName}
          selected={imageModal.selected}
          onChange={handleChangeImage}
        />
      </Modal>

      <Spinner show={loading} />
      <Alert {...alert} />

      <form onSubmit={handleSubmit}>
        <div className="mb-4 text-right">
          <button type="submit" className="btn btn-primary rounded-pill px-4">
            {t('Save')}
          </button>
        </div>

        {properties.map((property, i) => (
          <ContentEditorFormProperty
            key={i}
            projectName={projectName}
            property={property}
            value={value[property.name]}
            onChange={handleChange}
            setImageModal={setImageModal}
          />
        ))}
      </form>
    </div>
  );
}
