import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { createContent } from '../api/content.api';
import { Spinner, Alert } from '../util';
import BreadCrumb from '../component/BreadCrumb';

export default function Content(props) {
  const { t } = useTranslation();
  const { projectName, templateName } = useParams();

  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({});

  const [redirectTo, setRedirectTo] = React.useState();

  React.useEffect(() => {
    if (!projectName || !templateName) {
      return;
    }
    async function createNewContent() {
      try {
        setAlert({});
        setLoading(true);

        const contentId = await createContent(projectName, templateName);
        setRedirectTo(`/p/${projectName}/t/${templateName}/c/${contentId}`);
      } catch (error) {
        setAlert({ type: 'warning', message: `${error}` });
        setLoading(false);
      }
    }
    createNewContent();
  }, [projectName, templateName]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className="container-lg py-5">
      <BreadCrumb
        projectName={projectName}
        templateName={templateName}
        isContents
      />

      <h5>
        {t('Creating new')} {templateName}...
      </h5>

      <Spinner show={loading} />
      <Alert {...alert} />
    </div>
  );
}
