import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../component/BreadCrumb';
import ProjectTemplateList from './ProjectTemplateList';
import ProjectContentList from './ProjectContentList';
import ProjectImageList from './ProjectImageList';

export default function Project(props) {
  const { t } = useTranslation();
  const { projectName } = useParams();

  const [templates, setTemplates] = React.useState([]);

  return (
    <div className="container-lg py-5">
      <BreadCrumb projectName={projectName} />

      <div className="mb-5">
        <h3>
          <small className="mr-2">{t('Project')}</small>
          {projectName}
        </h3>
      </div>

      <ProjectContentList
        projectName={projectName}
        templates={templates}
        className="mb-5"
      />

      <ProjectImageList projectName={projectName} className="mb-5" />

      <ProjectTemplateList
        projectName={projectName}
        templates={templates}
        setTemplates={setTemplates}
        className="mb-5"
      />
    </div>
  );
}
