import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../home/Home';
import Project from '../project/Project';
import Template from '../template/Template';
import Content from '../content/Content';
import ContentNew from '../content/ContentNew';
import ContentList from '../content/ContentList';
import Secret from './Secret';

export default function Main(props) {
  return (
    <main role="main">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/143">
          <Secret />
        </Route>
        <Route path="/p/:projectName/t/:templateName/c/:contentId">
          <Content />
        </Route>
        <Route path="/p/:projectName/t/:templateName/n">
          <ContentNew />
        </Route>
        <Route path="/p/:projectName/t/:templateName/l">
          <ContentList />
        </Route>
        <Route path="/p/:projectName/t/:templateName">
          <Template />
        </Route>
        <Route path="/p/:projectName">
          <Project />
        </Route>
      </Switch>
    </main>
  );
}
