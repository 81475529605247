import React from 'react';

const PRECODE_STYLE = { whiteSpace: 'pre-wrap' };

export function PreCode(props) {
  const { className = '', style = {}, children } = props;
  return (
    <pre className={className} style={{ ...PRECODE_STYLE, ...style }}>
      <code>{JSON.stringify(children, null, 2)}</code>
    </pre>
  );
}
