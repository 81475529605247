const API_URL = process.env.REACT_APP_API_URL;

export async function getTemplates(projectName) {
  const url = `${API_URL}/template?projectName=${projectName}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

export async function getTemplate(projectName, templateName) {
  const url = `${API_URL}/template/${projectName}/${templateName}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const responseJSON = await response.json();
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const { message } = responseJSON;
    throw new Error(message);
  }
  return responseJSON;
}

export async function createTemplate(projectName, templateName) {
  const url = `${API_URL}/template`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      projectName,
      templateName,
      description: '',
      properties: [],
    }),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}

export async function updateTemplate(projectName, templateName, template) {
  const url = `${API_URL}/template`;
  const { description = '', properties = [] } = template;
  const response = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      projectName,
      templateName,
      description,
      properties,
    }),
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}

export async function removeTemplate(projectName, templateName) {
  const url = `${API_URL}/template/${projectName}/${templateName}`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
  if (!response.ok || (response.status >= 400 && response.status < 600)) {
    const responseJSON = await response.json();
    const { message } = responseJSON;
    throw new Error(message);
  }
  return await response.text();
}
