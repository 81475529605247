import React from 'react';
import Constant from '../Constant';
import { Link } from 'react-router-dom';

export default function Navbar(props) {
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-light">
      <Link className="navbar-brand" to="/">
        <span className="mr-3">{Constant.APP_LOGO}</span>
        {Constant.APP_NAME}
      </Link>
    </nav>
  );
}
