import React from 'react';
import ContentEditorFormPropertyInputMd from './ContentEditorFormPropertyInputMd';
import ContentEditorFormPropertyInputImage from './ContentEditorFormPropertyInputImage';

export default function ContentEditorFormPropertyInput(props) {
  const {
    projectName,
    id,
    name,
    inputType,
    value,
    onChange,
    setImageModal,
  } = props;

  switch (inputType) {
    case 'text':
      return (
        <input
          id={id}
          name={name}
          className="form-control"
          type={inputType}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
        />
      );
    case 'md':
      return (
        <ContentEditorFormPropertyInputMd
          id={id}
          name={name}
          value={value}
          onChange={onChange}
        />
      );
    case 'image':
      return (
        <ContentEditorFormPropertyInputImage
          projectName={projectName}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          setImageModal={setImageModal}
        />
      );
    default:
      return null;
  }
}
