import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function ProjectContentListEntry(props) {
  const { t } = useTranslation();
  const { projectName, template, className = '' } = props;

  const { name: templateName } = template;

  return (
    <div className={`p-3 rounded shadow-sm shadow-hover ${className}`}>
      <h5 className="mb-4">{templateName}</h5>
      <Link to={`/p/${projectName}/t/${templateName}/n`}>
        <button className="btn btn-success btn-block shadow-sm rounded-pill px-4 mb-3">
          {t('Create New')} {templateName}
        </button>
      </Link>
      <Link to={`/p/${projectName}/t/${templateName}/l`}>
        <button className="btn btn-info btn-block shadow-sm rounded-pill px-4 mb-3">
          {t('Contents of')} {templateName}
        </button>
      </Link>
    </div>
  );
}
