import React from 'react';
import { useTranslation } from 'react-i18next';
import { fromMd } from '../util';

export default function ContentEditorFormPropertyInputMd(props) {
  const { t } = useTranslation();
  const { id, name, value, onChange } = props;

  function handleChange(e) {
    onChange(name, e.target.value);
  }

  return (
    <div className="form-row">
      <div className="col-6">
        <textarea
          id={id}
          name={name}
          className="form-control"
          value={value}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <small>{t('Preview')}</small>
        <div
          className="p-3 rounded bg-light shadow-sm"
          dangerouslySetInnerHTML={fromMd(value)}
        />
      </div>
    </div>
  );
}
