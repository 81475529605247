import React from 'react';
import { useTranslation } from 'react-i18next';
import ProjectContentListEntry from './ProjectContentListEntry';

export default function ProjectContentList(props) {
  const { t } = useTranslation();
  const { projectName, templates, className = '' } = props;

  return (
    <div className={className}>
      <h4>
        {t('Contents')}
        <span className="ml-2 badge badge-info badge-pill">
          {templates.length}
        </span>
      </h4>
      <div className="form-row">
        {templates.map((template) => (
          <div key={template.name} className="col-12 col-sm-4">
            <ProjectContentListEntry
              projectName={projectName}
              template={template}
              className="h-100"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
